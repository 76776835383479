<template>
  <b-container fluid>
    <b-card no-body>
      <b-overlay :show="isLoading" rounded="sm">
        <div class="m-2">
          <b-row>
            <b-col cols="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="Học kỳ"
                  label-for="input-default"
              >
                <v-select
                    v-model="courseSemesterSelected"
                    label="name"
                    id="id"
                    :reduce="option => option.id"
                    :options="courseSemesterOption"
                />
              </b-form-group>
            </b-col>
            <!-- Per Page -->
          </b-row>
          <b-row class="mt-2">
            <b-col cols="12">
              <vue-good-table
                  class="my-table"
                  mode="remote"
                  :columns="columns"
                  :rows="dataSource"
                  :pagination-options="paginationOptions"
                  :total-rows="total"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
              >
                <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                >
                  Không có bản ghi nào !
                </div>
                <template
                    slot="table-row"
                    slot-scope="props"
                >
                    <span v-if="props.column.field === 'status'">
                      {{ getStatusName(props.row.status) }}
                    </span>

                  <!-- Column: Common -->
                  <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                </template>

                <!-- pagination -->
                <template
                    slot="pagination-bottom"
                    slot-scope="props"
                >
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap"> Hiển thị 1 đến </span>
                      <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                      />
                      <span class="text-nowrap">của {{ total }} bản ghi</span>
                    </div>
                    <div>
                      <b-pagination
                          :value="1"
                          :total-rows="total"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                      />
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>
  </b-container>
</template>

<script>
import {BButton, BCard, BCol, BContainer, BFormGroup, BFormSelect, BOverlay, BPagination, BRow} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'
import {mapActions, mapGetters} from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from "vue-ripple-directive";
import vSelect from 'vue-select'

export default {
  name: "PaymentStatusOfStudent",
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    BFormGroup,
    VueGoodTable,
    vSelect,
  },
  data() {
    return {
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        courseSemesterId: 1,
        paymentStatus: null,
        status: 1,
        sort: '',
      },
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      columns: [
        {
          label: 'MSV',
          field: 'studentCode',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          sortable: false,
          width: '8%',
          thClass: 'text-center',
        },
        {
          label: 'Tên sinh viên',
          field: 'studentName',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          sortable: false,
          width: '15%',
          thClass: 'text-center',
        },
        {
          label: 'Lớp',
          field: 'className',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          width: '10%',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Ngành',
          field: 'majorName',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          sortable: false,
          width: '15%',
          thClass: 'text-center',
        },
        {
          label: 'Khoản thu',
          field: 'revenueName',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          sortable: false,
          width: '10%',
          thClass: 'text-center',
        },
        {
          label: 'Dự kiến thu',
          field: 'amountPlan',
          sortable: false,
          width: '8%',
          thClass: 'text-center',
          tdClass: 'text-right',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Tất cả',
            filterDropdownItems: [],
          },
          width: '10%',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Đã thu',
          field: 'amountReceipt',
          sortable: false,
          width: '8%',
          thClass: 'text-center',
          tdClass: 'text-right',
        },
      ],
      dataSource: [],
      total: 0,
      courseSemesterOption: [],
      courseSemesterSelected: null,
      filterCourseSemester: {
        currentPage: 1,
        itemsPerPage: 1000,
        courseId: 1,
        status: 1,
      },
    }
  },
  computed: {
    ...mapGetters({
      dataList: 'reportPaymentStatusByCourseSemester/dataList',
      totalRows: 'reportPaymentStatusByCourseSemester/totalRows',
      statuses: 'reportPaymentStatusByCourseSemester/statuses',
      courseSemesters: 'courseSemester/dataLists'
    }),
  },
  async created() {
    this.isLoading = true
    try {
      await Promise.all([
        this.readData(this.filter),
        this.readCourseSemester(this.filterCourseSemester)
      ])
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      const statusesCV = this.statuses.map(item =>
          ({value: item.value, text: item.label})
      );
      this.$set(this.columns[6].filterOptions, 'filterDropdownItems', statusesCV);
      this.isLoading = false
      this.dataSource = this.dataList;
      this.total = this.totalRows;
      this.courseSemesterOption = this.courseSemesters
    }
  },
  methods: {
    ...mapActions({
      readData: 'reportPaymentStatusByCourseSemester/readData',
      readCourseSemester: 'courseSemester/getData'
    }),
    updateParams(newProps) {
      this.filter = {...this.filter, ...newProps}
    },
    async onPageChange(param) {
      this.updateParams({currentPage: param.currentPage})
      await this.getDataFromStore()
    },
    async getDataFromStore() {
      this.isLoading = true
      try {
        await this.readData(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.dataSource = this.dataList;
        this.total = this.totalRows;
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getDataFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const {field, type} = param[0]
        if (field === 'name') this.updateParams({sort: `1_${type}`})
        else if (field === 'orderNo') this.updateParams({sort: `2_${type}`})
        else this.updateParams({sort: `3_${type}`})
      }
      await this.getDataFromStore()
    },
    async onColumnFilter(param) {
      const {columnFilters} = param
      if (Object.hasOwn(columnFilters, 'studentName')) this.updateParams({studentName: columnFilters.studentName})
      if (Object.hasOwn(columnFilters, 'studentCode')) this.updateParams({studentCode: columnFilters.studentCode})
      if (Object.hasOwn(columnFilters, 'majorName')) this.updateParams({majorName: columnFilters.majorName})
      if (Object.hasOwn(columnFilters, 'className')) this.updateParams({className: columnFilters.className})
      if (Object.hasOwn(columnFilters, 'revenueName')) this.updateParams({revenueName: columnFilters.revenueName})
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({paymentStatus: columnFilters.status})
      await this.getDataFromStore()
    },
    getStatusName(id) {
      return this.statuses.find(status => status.value === id).label
    },
  },
}
</script>

<style scoped>

</style>
